@import "bootstrap/scss/bootstrap";

/* display background color black on navbar scroll */
.navbarScroll.navbarDark {
  background-color: black;
}

.navbarDark {
  @extend .navbar-dark;
}

.navbar {
  font-weight: bold;
}

h2 {
  font-size: 1.25rem;
}

/* hero background image */
.bgimage-hero {
  height:100vh;
  background: url('../resources/background-path.webp');
  background-size:cover;
  position:relative;
  background-position: center;
}

.bgimage-topics {
  background: url('../resources/lake.webp');
  background-size:cover;
  position:relative;
  background-position: center;
}

/* text css above hero image*/
.hero_title {
  font-size: 4.5rem;
}
.hero_desc {
  font-size: 2rem;
}
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

/* spacing on all sections */
/* #about, #services, #portfolio, #contact {
    margin-top: 4rem;
    padding-top: 4rem;
} */
#contact {
  padding-bottom: 4rem;
}
/* about section image css */
.imageAboutPage {
  width: 100%;
}

/* services section css */
.servicesText.card {
  height: 280px;
  cursor: pointer;
}
.servicesIcon {
  font-size: 36px;
  text-align: center;
  width: 100%;
}
/*.card-title {*/
/*    text-align: center;*/
/*}*/
/*.card:hover .servicesIcon {*/
/*    color: #008000;*/
/*}*/
/*.servicesText:hover {*/
/*    border: 1px solid #008000;*/
/*}*/

/* social media icons styling */
.social-icons {
  font-size: 36px;
  cursor: pointer;
}
.fa-facebook:hover,.fa-instagram:hover,.fa-twitter:hover,.fa-linkedin:hover, .fa-twitch:hover {
  color: #008000;
}
.fab {
  color: #000000;
}
/* footer styling */
#footer {
  background-color: #808080;
  text-align: center;
}

.anchor-pad {
  position: relative;
  padding: 15px;
  border-top: 52px solid transparent;
  margin-top: -50px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.anchor-pad:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
}